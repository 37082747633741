import React from "react"
import { Link, graphql } from "gatsby"

import ArticleHeader from "../../../components/athena/articleHeader.js"
import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Layout, { athenaTheme } from "../../../components/layout"
import MoreArticles from "../../../components/athena/moreArticles.js"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import SingleImage from "../../../components/zeus-modules/singleImage"

import styles from "./you-dont-know-me.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta
  const coverImages = article.coverImages ? article.coverImages[0] : null

  // Assets
  const consumerIdentity1 = data.craft.consumerIdentity1[0]
  const consumerIdentity2 = data.craft.consumerIdentity2[0]
  const consumerIdentity3 = data.craft.consumerIdentity3[0]
  const consumerIdentity4 = data.craft.consumerIdentity4[0]

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <ArticleHeader
            issue={article.issue}
            title={article.title}
            subtitle={article.articleSubTitle}
            coverImages={coverImages}
          />
          <div className={styles.articleBody}>
            <div className="wrapper">
              <p className={styles.statement}>
                In early October, I spent a weekend in Boston at{" "}
                <a
                  className="link-grow link-grow--heading"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.gsd.harvard.edu/event/black-in-design-2019-black-futurism-creating-a-more-equitable-future/"
                >
                  a conference
                </a>{" "}
                centered around Black Futurism . It was three intense days of
                “grappling with questions of equity and possibility,” and
                grapple we did.
              </p>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      We listened to panels of speakers talk about everything
                      from de-carceration to wellness to biomimicry. We got into
                      impassioned debates about whether we have the
                      responsibility to educate the non-Black population on the
                      contributions of Black people. Snaps and claps echoed all
                      around. There were multiple standing ovations when
                      something really resonated with the crowd. One thing that
                      struck me in those moments when we were all standing was
                      how I could look around and see all of the variation in
                      people’s hair styles. It was a reminder that Black doesn’t
                      look one way, and neither does anything else.
                    </p>
                    <p>
                      For three days, I got to sit in a tall auditorium in
                      Harvard’s Graduate School of Design building with nearly
                      300 other black designers. And I have never felt so out of
                      place. Or empowered. Or challenged. Or understood. Or
                      privileged.
                    </p>
                    <p>
                      The entire weekend was way more emotional than I expected
                      it to be. Half of the time I sat in that auditorium with a
                      lump in my throat, trying to decide if I wanted to slowly
                      slink out of the tight rows of people or stay stationed in
                      my plastic chair. The other half of the time I was
                      snapping, clapping, and “hm-mm”ing with everyone else.
                    </p>
                    <p>
                      I felt in between in so many ways. Everyone else seemed to
                      see things in such a cut-and-dried way. It was either
                      literally black or white. At one point, an architect
                      mentioned that she didn’t view herself as a Black
                      Designer, and the crowd audibly gasped. They couldn’t
                      fathom why anyone wouldn’t want to claim their blackness
                      in such a forward way. Maybe it’s the Gemini in me — or
                      the experience of growing up not-quite-black-enough and
                      not-quite-white-enough — but I see all of the shades of
                      gray. I’ve never seen things as just This Way or That.
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.singleImage}>
                <SingleImage
                  data={{
                    image: [consumerIdentity1],
                    mobileImage: [consumerIdentity1],
                    columnWidth: "6",
                  }}
                />
              </div>

              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      When it comes to how we talk about people in business,
                      though, it usually is This Way or That. We place people in
                      buckets and have them tick boxes. And it’s just human
                      nature. We organize to understand. And it’s hard to
                      understand people when they’re all over the place, even
                      though that’s what makes humans human. When we search out
                      people for user testing, we strive to make organization
                      out of the chaos that is individuality. We group people
                      according to their age or how much money they make. We ask
                      if they have children or if they have a college degree.
                      It’s just easier to generalize. To group and to bucket.
                    </p>
                    <p>
                      The keynote speaker at the conference, North Carolina-born
                      Pierce Freelon, who is running for Senate in his home
                      state, was talking about a TV project he’s working on
                      called “The History of White People in America.” When
                      describing the attitude of early settlers in America, he
                      said something that I now think about almost daily:
                      “Whiteness was used as a straightening device.” Settlers
                      sailed into the East Coast, to a place wrought with
                      mystery and unusualness, and needed a way to make
                      themselves feel powerful. The New World was tilted to them
                      and they used racial and class constructs to make order
                      from the chaos they perceived. They needed structure and
                      got it by arranging themselves and others in a way that
                      was ideal to them. And created their own world, their own
                      freedom, from there.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.statement} wrapper`}>
              <p>
                Subconsciously, I had always known this was true, even though
                they don’t teach us about this history in school. I have seen it
                in action in my own life as a biracial woman. People ask me
                questions that are pointed in such a way that I can tell they’re
                trying to figure out which bucket in their head to put me in.
              </p>
            </div>

            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      My friends and I code-switch based on who else we’re
                      around, a device many oppressed populations use to “right
                      themselves” to straight white people in situations where
                      their true self might not be accepted.
                    </p>
                    <p>
                      Obviously this straightening device created some
                      devastating results that we’re still living and reliving
                      the consequences of. But what if we came up with a new
                      “straightening” device? A new framework to build our
                      worlds around? And we used it to embrace the nuance of
                      every individual in order to create businesses and
                      products that consumers actually thought reflected who
                      they are. Or who they aspire to be. Or how they see the
                      world.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.singleImage}>
              <SingleImage
                data={{
                  image: [consumerIdentity2],
                  mobileImage: [consumerIdentity2],
                  columnWidth: "fullBleed",
                }}
              />
            </div>

            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena-no-headings">
                    <h2 className="a-h-2">The Business Case</h2>
                    <p>
                      Businesses that are thriving right now embrace the fact
                      that their audience is made up of nuanced individuals. And
                      that they’re going to change. Glossier started their brand
                      with skincare first, because that’s what their{" "}
                      <a
                        className="link-grow"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.stateofdigitalpublishing.com/digital-publishing/glossier-evolution/"
                      >
                        ever-growing audience told them they needed
                      </a>
                      . As younger people joined the brand’s loyal group of
                      followers and commenters (read: super-rich-data
                      inputters), Glossier added makeup made for every situation
                      their customer might find themselves in. The brand changes
                      their visual look a bit each time they pop up in a new
                      city so they resonate better with the context and customer
                      they’re encountering.
                    </p>
                    <p>
                      Lifestyle hub{" "}
                      <a
                        className="link-grow"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.manrepeller.com/about"
                      >
                        Man Repeller
                      </a>{" "}
                      went from being an influencer blog based on one woman’s
                      personal style to an “expansive constellation of things
                      women care about.” Their about page even states that they
                      know different people come to their site to get different
                      things and feel different ways. These businesses are based
                      on the ways in which we are different as individuals.
                      Businesses like these are built on flexibility and use it
                      as a core operating value.
                    </p>
                    <p>
                      But the strategic frameworks we use to look at people
                      right now are pretty static. They view people in a way
                      that’s too flat. And they only work when people check a
                      single box per category. These models break when people
                      need to check several — which we all do. I still think
                      about the fact that picking an Ethnicity box on my SAT
                      test six years ago was the most stressful part. And I’m
                      not alone.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.singleImage}>
              <SingleImage
                data={{
                  image: [consumerIdentity3],
                  mobileImage: [consumerIdentity3],
                  columnWidth: "6",
                }}
              />
            </div>

            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      You’ve heard it before, but I’ll say it again: Gen Z is
                      the most diverse generation ever. Almost half of them are
                      people of color. They come from multiple backgrounds and
                      must check many boxes. In coming years, they’ll tip the
                      scale and make minorities the majority. People born from
                      1997 to 2011 already account for more than 25% of the U.S.
                      population. Sixty-one million Gen Zers will be in the work
                      force by 2029. They will have twice the spending power of
                      Millennials, at $143 billion. And we’re still treating
                      them like we did their grandparents when it comes to
                      marketing and brand building.
                    </p>
                    <p>
                      Gen Z is self-reliant and self-aware. They view their
                      identity and their values as amorphous things that are one
                      hundred percent open to changing and flexing as life goes
                      on. Gen Zers adapt their selfhood for all sorts of
                      situations and are content in the fluidity of it all. And
                      perhaps most importantly, they know marketing bullshit
                      when they see it because they’ve grown up surrounded by it
                      in both physical and digital forms.
                    </p>
                    <blockquote className={styles.quote}>
                      <p>
                        People have 3D lives that are filled with thoughts,
                        dreams, complexities, and contradictions.
                      </p>
                    </blockquote>
                    <p>
                      So in order to build next-gen brands that truly cater to
                      the next generation of consumers, we’ve got to change how
                      we look at people. We’ve got to create new tools that are
                      radically inclusive and measure people with all of their
                      depth. And we can’t keep looking at people as a set of 2D
                      checked boxes. People have 3D lives that are filled with
                      thoughts, dreams, complexities, and contradictions. If
                      that sounds like a business and strategic nightmare,
                      you’re right. One thing isn’t going to work for everyone.
                      And we’ve got to embrace that, because it’s also the
                      business opportunity. Pierce said in his opening keynote
                      that “all organizing is science fiction.” We’re just
                      trying our best to build out our fantasies when we try to
                      plan and configure.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.singleImage}>
              <SingleImage
                data={{
                  image: [consumerIdentity4],
                  mobileImage: [consumerIdentity4],
                  columnWidth: "6",
                }}
              />
            </div>

            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      Another conference speaker said this, though: “The tools
                      for designing futures are not a monolith.” And it couldn’t
                      be more true. We’re not going to land on a single
                      framework that works for every project or every business
                      or every set of consumers. And it’s going to take a lot of
                      nuance and perspective to make sure we’re not just
                      building new boxes for people to check, but getting rid of
                      the boxes altogether. And we have to give it a shot, as
                      ambitious as it may seem. We’ve got to be in the business
                      of{" "}
                      <Link to={`/${data.craft.definingAudiences[0].uri}`}>
                        organizing for individuality
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <AthenaCta data={athenaCta} />
            <MoreArticles id={article.id} issue={article.issue[0].id} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      consumerIdentity1: assets(id: 3196) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      consumerIdentity2: assets(id: 3197) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      consumerIdentity3: assets(id: 3198) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      consumerIdentity4: assets(id: 3199) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      definingAudiences: entries(id: 584) {
        uri
      }
    }
  }
`
